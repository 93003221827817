@import "../variables.scss";

.m-body-resource {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  h3 {
    margin-bottom: 0.5rem;
  }
  div {
    display: flex;
    gap: 0px;
    flex-direction: column;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    background-color: $grey-500;
    border-radius: 12px;
    padding: 1rem;
    label {
      margin-top: 0.5rem;
    }
    select, input {
      margin-bottom: 1rem;
    }
  }
}

.iconModalContainer {
  border-radius: 12px;
  border: 1px dashed $green-100;
  width: 50px;
  height: 50px;
  padding: 10px;
  i {
    width: 100%;
    height: 100%;
    color: $green-100 !important;
  }
}