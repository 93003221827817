@import "../variables.scss";

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 20px;
  max-width: 100%;
  min-height: 194px;
  border: 1px solid $grey-300;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0 12px 16px -4px rgba(16, 24, 40, 0.08);
  border-radius: 8px;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;

  .card-head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    div {
      display: inline-flex;
      text-align: left;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2px;
    }
  }
  button {
    display: flex;
    width: -webkit-fill-available;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 40px;
    background-color: $grey-500;
    margin: 0 -20px -20px -20px;
    color: $blue-100;
    border: 1px solid $grey-500;
    border-radius: 0 0 8px 8px;
    svg {
      stroke: $blue-100;
    }
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      background-color: $green-200;
      color: white;
    }

    &:disabled {
      background-color: $grey-500;
      border-color: $grey-300;
      color: white;
      cursor: not-allowed;
    }
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $green-500;
    color: $black-100;
    border-radius: 16px;
    flex-wrap: wrap;
    padding: 5px 8px;
    font-size: 13px;
    margin-bottom: 10px;
  }
  label {
    font-size: 12px;
  }
  &:hover {
    transform: scale(1.03);
    color: white;
    border-color: $green-100;
  }
  .highlight-box {
    display: flex;
    justify-content: space-between;
    background-color: $b-light-grey;
    border-radius: 12px;
    padding: 1rem;
    border: 1px solid $grey-400;
  }
  .badge-current {
    background-color: $green-500;
    color: $blue-100;
    font-weight: 500;    
  }
  .badge-pending {
    background-color: $yellow-400;
    color: $blue-100;
    font-weight: 500;    
  }
  .badge-new {
    background-color: $grey-500;
    color: $blue-100;
    font-weight: 500;    
  }
  .badge-rejected {
    background-color: $red-100;
    color: $blue-100;
    font-weight: 500;    
  }
}
