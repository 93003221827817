@import "../variables.scss";

.warning-alert {
  display: flex;
  padding: 8px 10px;
  color: $black-100;
  background-color: $yellow-300;
  gap: 5px;
  border-radius: 8px;
  border: 1px solid $yellow-100;
  align-items: center;
  justify-content: center;
}

abp-page-alert-container {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 1001;
  color: $black-100;
  div {
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.25);
    span,
    h4 {
      color: $black-100;
    }
  }
}

.confirmation-dialog {
  .footer {
    justify-content: space-between !important;
  }
  button {
    min-width: 130px !important;
  }
}
.confirmation .confirmation-backdrop {
  background: #202020f8 !important;
}