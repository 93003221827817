@import "../variables.scss";
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #fff !important;
  border: 1px solid $grey-300;
  border-radius: 8px;
  padding: 10px 14px;
  width: 100%;
  height: 40px;
  &:focus {
    outline-color: $green-100;
    box-shadow: 0 0 4px 1px rgba(108, 166, 33, 0.5);
  }
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #fff !important;
  border: 1px solid $grey-300;
  border-radius: 8px;
  padding: 10px 14px;
  width: 100%;
  height: 40px;
  &:focus {
    outline-color: $green-100;
    box-shadow: 0 0 4px 1px rgba(108, 166, 33, 0.5);
  }
}

form {
  .form-check-input {
    background-color: #fff !important;
    width: 2.25em;
    height: 2.25em;
  }
}

.form-switch .form-check-input {
  width: 3em;
  border-color: $grey-300 !important;
  &:checked {
    background-color: $green-100 !important;
    border-color: $green-100 !important;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #fff !important;
  border: 1px solid $grey-300;
  border-radius: 8px;
  padding: 10px 14px;
  width: 100%;
  height: 40px;
  background-image: url("../assets/images/ico/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  &:focus {
    outline-color: $green-100;
    box-shadow: 0 0 4px 1px rgba(108, 166, 33, 0.5);
  }
}

.search-input {
  position: relative;
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: #fff !important;
    border: 1px solid $grey-300;
    border-radius: 8px;
    padding: 10px 14px;
    width: 100%;
    height: 40px;
    background-image: url("../assets/images/ico/ico_search.svg");
    background-repeat: no-repeat;
    background-position: left 10px center;
    padding-left: 35px;

    &:focus {
      outline-color: $green-100;
      box-shadow: 0 0 4px 1px rgba(108, 166, 33, 0.5);
    }
  }
}
.reseticon {
  position: absolute;
  right: 30px;
  top: 20%;
  color: grey;
  border: 0px solid;
  outline: none;
  background-color: white;
  &:hover {
    background-color: $b-light-grey;
    border-radius: 50%;
  }
}

.chipsMachine {
  display: inline-flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  background-color: $grey-500;
  color: $black-100;
  font-weight: 700;
  border-radius: 32px;
  gap: 1rem;
  align-items: center;
  border: 1px solid $blue-100;
  p {
    margin-bottom: 0;
  }
  button {
    background-color: $blue-100;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    height: 20px;
    align-items: center;
    border-radius: 100%;
    padding: 5px 5px;
    min-width: 20px;
    border: 1px solid $blue-100;
    outline: none;
    color: white;
    width: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
    font-weight: 500;
    font-size: 0.75rem;
    text-wrap: nowrap;
  
    &:hover {
      background-color: $grey-300;
      transform: scale(1.03);
      color: white;
    }
  }
}

.chipsMachine-error {
  display: inline-flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  background-color: $red-100;
  color: $black-100;
  font-weight: 700;
  border-radius: 32px;
  gap: 1rem;
  align-items: center;
  border: 1px solid $blue-100;
  p {
    margin-bottom: 0;
  }
  button {
    background-color: $blue-100;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    height: 20px;
    align-items: center;
    border-radius: 100%;
    padding: 5px 5px;
    min-width: 20px;
    border: 1px solid $blue-100;
    outline: none;
    color: white;
    width: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
    font-weight: 500;
    font-size: 0.75rem;
    text-wrap: nowrap;
  
    &:hover {
      background-color: $grey-300;
      transform: scale(1.03);
      color: white;
    }
  }
}