@import "../variables.scss";
//ICONS

.ico-home {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_home.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ico-operator {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_operador.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ico-fronts {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_fronts.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ico-machines {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_machines.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ico-parts {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_parts.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ico-requests {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_requests.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ico-reload {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_reload.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 19px;
  height: 19px;
}
.ico-leaf {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_leaf.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

// --FA ICON REPLACE WITH CUSTOM--
.fas.fa-atom {
  background: url('/assets/images/ico/ico_leaf_w.svg') no-repeat center center;
  display: inline-block;
  font-size: 0;
  text-indent: -9999px;
  background-size: contain;
  transition: color 0.3s;
  height: 1.3rem;
  width: 24px;
  height: 24px;
}

.fas.fa-atlas {
  background: url('/assets/images/ico/ico_requests_w.svg') no-repeat center center;
  display: inline-block;
  font-size: 0;
  text-indent: -9999px;
  background-size: contain;
  transition: color 0.3s;
  height: 1.3rem;
  width: 24px;
  height: 24px;
}

.fas.fa-bahai {
  background: url('/assets/images/ico/ico_fronts.svg') no-repeat center center;
  display: inline-block;
  font-size: 0;
  text-indent: -9999px;
  background-size: contain;
  transition: color 0.3s;
  height: 1.3rem;
  width: 24px;
  height: 24px;
}

.lpx-menu-item-link.selected {
  .fas.fa-atom {
    background: url('/assets/images/ico/ico_leaf.svg') no-repeat center center;
    height: 1.3rem;   
    background-size: contain;
  }
  .fas.fa-atlas {
    background: url('/assets/images/ico/ico_requests.svg') no-repeat center center;
    height: 1.3rem;   
    background-size: contain;
  }
  .fas.fa-bahai {
    background: url('/assets/images/ico/ico_fronts_b.svg') no-repeat center center;
    height: 1.3rem;   
    background-size: contain;
  }
}

.lpx-mobile-nav-tab .menu-item-icon {  
  display: flex;
  margin: 6px auto; 
}

// MODAL ICONS
.ico-leaf-modal {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_leaf_g.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.ico-request-modal {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_requests_g.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.ico-parts-modal {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_parts_g.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.ico-programming-modal {
  display: inline-flex;
  background-image: url("/assets/images/ico/ico_fronts_g.svg");
  background-repeat: no-repeat;
  background-size: cover;
}