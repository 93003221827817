@import "../../../variables.scss";

.bc-primary {
  background-color: $green-100;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  align-items: center;
  border-radius: 8px;
  padding: 10px 18px;
  min-width: 130px;
  border: 1px solid $green-100;
  outline: none;
  color: white;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-weight: 500;
  text-wrap: nowrap;

  &:disabled {
    background-color: $grey-300;
    border-color: $grey-300;
    color: white;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $green-200;
    transform: scale(1.03);
  }
}

.bc-primary-small {
  background-color: $green-100;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  border-radius: 8px;
  padding: 5px 5px;
  min-width: 30px;
  height: 30px;
  border: 1px solid $green-100;
  outline: none;
  color: white;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-weight: 500;
  font-size: 0.75rem;
  text-wrap: nowrap;

  &:disabled {
    background-color: $grey-300;
    border-color: $grey-300;
    color: white;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $green-200;
    transform: scale(1.03);
  }
}

.bc-secondary {
  background-color: white;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  align-items: center;
  border-radius: 8px;
  padding: 10px 18px;
  min-width: 130px;
  border: 1px solid $blue-100;
  outline: none;
  color: $blue-100;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-weight: 500;
  text-wrap: nowrap;

  &:hover {
    background-color: $blue-100;
    transform: scale(1.03);
    color: white;
  }

  &:disabled {
    border-color: $green-300;
    color: $grey-300;
    cursor: not-allowed;    

    &:hover {
      background-color: $grey-300;
      transform: scale(1.03);
      color: white;
    }
  }
}

.bc-secondary-small {
  background-color: white;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  height: 30px;
  align-items: center;
  border-radius: 8px;
  padding: 5px 5px;
  min-width: 30px;
  border: 1px solid $blue-100;
  outline: none;
  color: $blue-100;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-weight: 500;
  font-size: 0.75rem;
  text-wrap: nowrap;

  &:hover {
    background-color: $blue-100;
    transform: scale(1.03);
    color: white;
  }

  &:disabled {
    border-color: $grey-300;
    color: $grey-300;
    cursor: not-allowed;    

    &:hover {
      background-color: $grey-300;
      transform: scale(1.03);
      color: white;
    }
  }
}

.bc-transparent {
  background-color: white;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  align-items: center;
  padding: 10px 18px;
  min-width: 130px;
  border: 0px solid;
  outline: none;
  color: $green-100;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-weight: 600;
  text-wrap: nowrap;

  &:hover {
    border: 1px solid $grey-300;
    border-radius: 8px;
    transform: scale(1.03);
  }

  &:disabled {
    border-color: $green-300;
    color: $grey-300;
    cursor: not-allowed;   

    &:hover {
      background-color: $grey-300;
      border-radius: 8px;
      transform: scale(1.03);
      color: white;
    }
  }
}

.bc-transparent-small {
  background-color: white;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  height: 30px;
  align-items: center;
  padding: 5px 5px;
  min-width: 30px;
  border: 0px solid;
  outline: none;
  color: $green-100;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-weight: 600;
  font-size: 0.75rem;
  text-wrap: nowrap;

  &:hover {
    border: 1px solid $grey-300;
    border-radius: 8px;
    transform: scale(1.03);
  }

  &:disabled {
    border-color: $green-300;
    color: $grey-300;
    cursor: not-allowed;  

    &:hover {
      background-color: $grey-300;
      border-radius: 8px;
      transform: scale(1.03);
      color: white;
    }
  }
}

.bc-img {
  background-color: $green-100;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  align-items: center;
  border-radius: 8px;
  padding: 10px 18px;
  min-width: 130px;
  border: 1px solid $green-100;
  outline: none;
  color: white;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  text-wrap: nowrap;
  &:hover {
    background-color: $green-200;
    transform: scale(1.03);
    color: white;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  i {
    height: 30px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bc-img-small {
  background-color: $green-100;
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  height: 30px;
  align-items: center;
  border-radius: 8px;
  padding: 5px 5px;
  min-width: 30px;
  border: 1px solid $green-100;
  outline: none;
  color: white;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-size: 0.75rem;
  text-wrap: nowrap;
  &:hover {
    background-color: $green-200;
    transform: scale(1.03);
    color: white;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  i {
    height: 20px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bc-img-secondary {
  background-color: white;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  align-items: center;
  border-radius: 8px;
  padding: 10px 18px;
  min-width: 130px;
  border: 1px solid $green-100;
  outline: none;
  color: $green-100;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-weight: 500;
  text-wrap: nowrap;
  i {
    height: 30px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: $green-200;
    transform: scale(1.03);
    color: white;
  }

  &:disabled {
    border-color: $green-300;
    color: $grey-300;
    cursor: not-allowed;   

    &:hover {
      background-color: $grey-300;
      transform: scale(1.03);
      color: white;
    }
  }
}

.bc-img-secondary-small {
  background-color: white;
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  height: 30px;
  align-items: center;
  border-radius: 8px;
  padding: 5px 5px;
  min-width: 30px;
  border: 1px solid $green-100;
  outline: none;
  color: $green-100;
  width: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-weight: 500;
  font-size: 0.75rem;
  text-wrap: nowrap;
  i {
    height: 20px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: $green-200;
    transform: scale(1.03);
    color: white;
  }

  &:disabled {
    border-color: $green-300;
    color: $grey-300;
    cursor: not-allowed;   

    &:hover {
      background-color: $grey-300;
      transform: scale(1.03);
      color: white;
    }
  }
}

.bc-loading {
  background-color: $green-100;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  align-items: center;
  border-radius: 8px;
  padding: 10px 18px;
  min-width: 130px;
  border: 1px solid $green-100;
  outline: none;
  color: white;
  width: 100%;
  cursor: progress;
  transition: transform 0.3s ease;
  font-weight: 500;
  text-wrap: nowrap;

  &:hover {
    background-color: $green-200;
    transform: scale(1.03);
  }
  &:disabled {
    background-color: $grey-300;
    border-color: $grey-300;
  }
}

.load-container {
  --load-size: 20px;
  --load-color: white;
  --load-speed: 1s;
  --load-stroke: 3.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--load-size);
  height: calc(var(--load-size) * 0.9);
}

.bar {
  width: var(--load-stroke);
  height: 100%;
  background-color: var(--load-color);
  transition: background-color 0.3s ease;
}

.bar:nth-child(1) {
  animation: grow var(--load-speed) ease-in-out calc(var(--load-speed) * -0.45)
    infinite;
}

.bar:nth-child(2) {
  animation: grow var(--load-speed) ease-in-out calc(var(--load-speed) * -0.3)
    infinite;
}

.bar:nth-child(3) {
  animation: grow var(--load-speed) ease-in-out calc(var(--load-speed) * -0.15)
    infinite;
}

.bar:nth-child(4) {
  animation: grow var(--load-speed) ease-in-out infinite;
}

@keyframes grow {
  0%,
  100% {
    transform: scaleY(0.3);
  }

  50% {
    transform: scaleY(1);
  }
}

//---BTN COLORS---

.btn-grey {
  color: $grey-100 !important;
  i,
  svg {
    color: $grey-100 !important;
  }
}

.btn-black {
  color: $black-100 !important;
  i,
  svg {
    color: $black-100 !important;
  }
}

.btn-orange {
  color: $orange-100 !important;
  i,
  svg {
    color: $orange-100 !important;
  }
}

.btn-red {
  color: red !important;
  i,
  svg {
    color: red !important;
  }
}

.btn-blue {
  color: $blue-100 !important;
  i,
  svg {
    color: $blue-100 !important;
  }
}

.btn-outline-primary {
  border-color: #455269 !important;
  --bs-btn-color: #455269 !important;
  --bs-btn-hover-bg: #455269!important;
  --bs-btn-hover-border-color: #455269 !important;

}
.btn-primary {
  --bs-btn-bg: #7aba28 !important;
  --bs-btn-border-color: #7aba28 !important;
  --bs-btn-hover-bg: #9bcb5d !important;
  --bs-btn-active-bg: #7aba28 !important;
  --bs-btn-disabled-bg: #9bcb5d !important;
  --bs-btn-disabled-border-color: #9bcb5d !important;
  --bs-btn-hover-border-color: #9bcb5d !important;
}