/* You can add global styles to this file, and also import other style files */
@import "./variables.scss";

// BUTTONS STYLES IMPORT
@import "../src/app/components/buttons/custom-button.component.scss";

//CARDS IMPORT
@import "./styles/cards.scss";

//MODALS IMPORT
@import "../src/styles/modals.scss";

//FORMS IMPORT
@import "../src/styles/forms.scss";

//TABLES IMPORT
@import "../src/styles/tables.scss";

//ICONS IMPORT
@import "../src/styles/icons.scss";

//ALERTS STYLES
@import "../src/styles/alerts.scss";

:root .lpx-theme-dark {
  --lpx-logo: url("/assets/images/logo/upm_logo_white.svg");
  --lpx-logo-icon: url("/assets/images/logo/icon.svg");
  --lpx-navbar-color: #202020;
  --lpx-navbar-text-color: #ffffff;
  --lpx-content-bg: #ffffff;
  --lpx-navbar-active-text-color: #f6f6f6;
  --lpx-brand: #202020;
}

:root .lpx-theme-dim {
  --lpx-logo: url("/assets/images/logo/upm_logo_white.svg");
  --lpx-logo-icon: url("/assets/images/logo/icon.svg");
  --lpx-navbar-color: #202020;
  --lpx-navbar-text-color: #ffffff;
  --lpx-content-bg: #ffffff;
  --lpx-navbar-active-text-color: #f6f6f6;
  --lpx-brand: #202020;
}

:root .lpx-theme-light {
  --lpx-logo: url("/assets/images/logo/upm_logo_white.svg");
  --lpx-logo-icon: url("/assets/images/logo/icon.svg");
  --lpx-navbar-color: #202020;
  --lpx-navbar-text-color: #ffffff;
  --lpx-content-bg: #ffffff;
  --lpx-navbar-active-text-color: #f6f6f6;
  --lpx-brand: #202020;
}
:root {
  --lpx-theme-light-bg: url("/assets/images/login/login-bg-img-light.svg");
  --lpx-theme-dim-bg: url("/assets/images/login/login-bg-img-dim.svg");
  --lpx-theme-dark-bg: url("/assets/images/login/login-bg-img-dark.svg");
}

//---- DESKTOP MENU - STYLES
lpx-menu-filter {
  display: none;
}

.lpx-menu-item-link.selected {
  color: #202020 !important;
}

.lpx-mobile-nav-tab .mobile-item-text {
color: #f6f6f6;
}

.lpx-nav {
  padding-top: 0;
}

.lpx-sidebar .lpx-logo-container {
  position: unset;
}

.lpx-brand-logo {
  background-size: auto;
  height: 60px;
  background-position: left;
}

.menu-collapse-icon {
  display: none;
}

.lpx-nav-menu .lpx-menu-item-link.expanded {
  background-color: $grey-300;
}

//---- MOBILE CUSTOM MENU - STYLES

//----Modal position mobile
@media only screen and (max-width: 767px) {
  .modal {
    position: fixed;
    top: auto;
    right: auto;
    left: auto;
    bottom: 0;
    max-height: 95vh;
  }
}

//----FONTS STYLING
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  li {
    list-style-type: none;
  }
  --bs-backdrop-bg: #202020 !important;
  --bs-nav-tabs-link-active-color: #7aba28 !important;
  --bs-nav-tabs-link-active-border-color: #7aba28 !important;
  --bs-nav-pills-link-active-color: #7aba28 !important;
  --bs-nav-pills-link-active-bg: #deeec9 !important;
  --bs-dropdown-link-active-bg: #7aba28 !important;
  --bs-card-bg: white  !important;
  --lpx-card-bg: white !important;
}

h1 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 0;
  color: $black-100;
}

h2 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 0;
  color: $black-100;
}

h3 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0;
  color: $black-100;
}

h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  color: $black-100;
}

p {
  color: #666;
}

label {
  color: $blue-100;
  font-size: 0.8rem;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 1.56rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.3rem;
  }
}

//CARDS
.card {
  margin-bottom: 0 !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto !important;
  justify-content: space-between;
  gap: 0.5rem;
}

.modal-header {
  align-items: start !important;
}

// TABS STYLING
.nav-tabs .nav-item .nav-link.active {
  border-bottom: 3px solid $green-100;
}
.nav-tabs {
  border-bottom: 1px solid #00000030;
  border-radius: 0;
}

//chequed
.form-check-input:checked {
  background-color: $green-100 !important;
  border-color: $green-100 !important;
}

 //CALENDAR COMPONENT
 .ngb-dp-weekday, .ngb-dp-week-number {
  font-style: normal !important;
  font-weight: 600 !important;
}
.ngb-dp-month-name {
  color: $orange-100 !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}
ngb-datepicker {
  border-radius: 12px !important;
  width: 480px;
}   

.ngb-dp-months {
  flex-wrap: wrap !important;
}

//MODAL HEIGHT MOBILE
.modal-body {
  height: 70vh !important;
}
