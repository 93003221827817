//APP COLORS
$green-100: #7aba28;
$green-200: #9bcb5d;
$green-300: #bcdc93;
$green-400: #deeec9;
$green-500: #f1f8e8;
$black-100: #202020;
$black-200: #333;
$grey-100: #676362;
$grey-200: #8d8a89;
$grey-300: #b3b1b0;
$grey-400: #d9d8d8;
$grey-500: #f0efef;
$b-dark-grey: #3d3d3d;
$b-light-grey: #f8f8f8;
$b-light-green: #f1f8e8;
$blue-100: #455269;
$blue-200: #747d8f;
$blue-300: #a2a8b4;
$blue-400: #d0d4d9;
$blue-500: #ecedf0;
$orange-100: #ef7726;
$orange-200: #f3995c;
$orange-300: #f7bb92;
$orange-400: #fbddc9;
$orange-500: #fdf1e9;
$yellow-100: #feca33;
$yellow-200: #fed766;
$yellow-300: #fee499;
$yellow-400: #fff2cc;
$yellow-500: #fffaea;
$red-100: #FDA0A0;