@import "../variables.scss";
.table-fronts {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 100%;
  border: 1px solid $grey-400;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  thead {
    background-color: #f0efef;
    border-radius: 12px 12px 0 0;
  }
  tr {
    display: flex;
  }

  td,
  th {
    text-align: center;
    padding: 8px;
    flex: 1;
  }
  p {
    margin-bottom: 0;
  }
  th {
    text-transform: none;
  }
  tr:nth-last-child(1) td {
    border-bottom-width: 0;
  }
}
.blue-header {
    background-color: $blue-100 !important;
   th {
    color: white !important;
    padding: 1rem 0;
   }
}

.status-badge-current {
  background-color: $green-500;
  color: $blue-100;
  font-weight: 500;
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
}
.status-badge-pending {
  background-color: $yellow-400;
  color: $blue-100;
  font-weight: 500;
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
}
.status-badge-new {
  background-color: $grey-500;
  color: $blue-100;
  font-weight: 500;
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
}